@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Regular.ttf") format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Medium.ttf") format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-SemiBold.ttf") format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-MediumItalic.ttf") format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-SemiBoldItalic.ttf") format('truetype');
  font-weight: 600;
  font-style: italic;
}

body {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  margin: 0;
  font-family: "Poppins", Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
