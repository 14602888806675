$ayda-primary: #0C4A45;

main {
  margin: 20px;
  max-width: 600px;

  button {

    font-size: 1.1em;
    padding: 10px;
    background-color: $ayda-primary;
    border-radius: 5px;
    color: white;
    cursor: pointer;

  }

}
